<!--
  服务用户列表
 -->
<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        placeholder="请输入用户名搜索"
        v-model="keywords"
        size="small"
        style="width:200px;margin-right: 10px;"
        @keyup.native.enter="onSearch" />
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="technical"
        placeholder="请选择技术领域"
        clearable
        @change="onSearch">
        <el-option
          v-for="item in technicalList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="province"
        placeholder="请选择所在区域"
        clearable
        filterable
        @change="onSearch">
        <el-option
          v-for="item in staticData.cities"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="relationship"
        placeholder="请选择机构类型"
        clearable
        @change="onSearch">
        <el-option
          v-for="item in orgTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button
        type="primary"
        plain
        size="small"
        :loading="exportBtnLoading"
        @click="onExport">
        导出
      </el-button>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="onDetail">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="username"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="contactEmail"
          label="邮箱">
        </el-table-column>
        <el-table-column
          label="机构类型">
          <template slot-scope="scope">
            <span v-if="scope.row.organizationRole === 0">挂靠</span>
            <template v-else>
              <span v-if="scope.row.orgType === 0">非法人入驻</span>
              <span v-else>法人入驻</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        layout="prev, pager, next, jumper"
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import { renderExcel } from '@/utils/excel.export.js'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      exportBtnLoading: false,
      keywords: '', // 关键词
      technical: '', // 技术领域id
      province: '', // 省份选择器
      relationship: '', // 法人类型
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      // 机构类型筛选项
      orgTypeOptions: [
        {
          label: '法人机构',
          value: 1
        },
        {
          label: '非法人机构',
          value: 2
        },
        {
          label: '挂靠',
          value: 3
        }
      ]
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    }
  },
  created () {
    this.getTableData(1)
  },
  methods: {
    // 获取表格
    getTableData (pageNum) {
      this.loading = true

      const searchVar = this.getSearchVar()
      api.getAllUserList({
        pageNum,
        pageSize: this.pagination.pageSize,
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 导出
    onExport () {
      this.exportBtnLoading = true

      const searchVar = this.getSearchVar()
      api.exportAllUser({
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          console.log('用户导出', res.data.data)
          const { list, header } = res.data.data
          renderExcel(list, header, '服务用户数据')
        } else {
          this.$message.error(res.data.message)
        }
        this.exportBtnLoading = false
      }).catch(err => {
        this.exportBtnLoading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取搜索条件
    getSearchVar () {
      return {
        role: globalVar.USER_ROLE_1,
        keywords: this.keywords,
        technical: this.technical,
        province: this.province,
        relationship: this.relationship
      }
    },
    // 搜索
    onSearch () {
      this.getTableData(1)
    },
    onDetail (row) {
      window.open(`/account-manage/user-list/detail/${row.id}`, '_blank')
    },
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabpane_content {
  padding: 15px 25px;
  .content_header {
    display: flex;
    margin-bottom: 20px;
  }
  .content_main {
    margin-bottom: 20px;
  }
  .content_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
}
</style>
