<!--
  普通用户列表
 -->
<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        placeholder="请输入用户名搜索"
        v-model="keywords"
        size="small"
        style="width:340px;margin-right: 10px;"
        @keyup.native.enter="onSearch" />
      <el-button
        type="primary"
        plain
        size="small"
        :loading="exportBtnLoading"
        @click="onExport">
        导出
      </el-button>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="onDetail">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="username"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="contactEmail"
          label="邮箱">
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        layout="prev, pager, next, jumper"
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import { renderExcel } from '@/utils/excel.export.js'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      exportBtnLoading: false,
      keywords: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    }
  },
  created () {
    this.getTableData(1)
  },
  methods: {
    // 获取表格
    getTableData (pageNum) {
      this.loading = true

      const searchVar = this.getSearchVar()
      api.getAllUserList({
        pageNum,
        pageSize: this.pagination.pageSize,
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 导出
    onExport () {
      this.exportBtnLoading = true

      const searchVar = this.getSearchVar()
      api.exportAllUser({
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          console.log('用户导出', res.data.data)
          const { list, header } = res.data.data
          renderExcel(list, header, '普通用户数据')
        } else {
          this.$message.error(res.data.message)
        }
        this.exportBtnLoading = false
      }).catch(err => {
        this.exportBtnLoading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取搜索条件
    getSearchVar () {
      return {
        keywords: this.keywords,
        role: globalVar.USER_ROLE_0
      }
    },
    // 搜索
    onSearch () {
      this.getTableData(1)
    },
    onDetail (row) {
      const { href } = this.$router.resolve({
        path: `/account-manage/user-list/detail/${row.id}`
      })

      window.open(href, '_blank')
    },
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabpane_content {
  padding: 15px 25px;
  .content_header {
    display: flex;
    margin-bottom: 20px;
  }
  .content_main {
    margin-bottom: 20px;
  }
  .content_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
}
</style>
