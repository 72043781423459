<!-- 服务机构账号审核 -->
<template>
  <div class="card">
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="普通用户" name="1" lazy>
        <pt-table></pt-table>
      </el-tab-pane>
      <el-tab-pane label="服务用户" name="2" lazy>
        <fw-table></fw-table>
      </el-tab-pane>
      <el-tab-pane label="服务集成商" name="3" lazy>
        <jcs-table></jcs-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ptTable from './components/pt-table'
import fwTable from './components/fw-table'
import jcsTable from './components/jcs-table.vue'

export default {
  components: {
    ptTable,
    fwTable,
    jcsTable
  },
  data () {
    return {
      activeTab: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    width: 980px;
    border-radius: 4px;
  }
</style>
